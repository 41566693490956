import { Types } from '@scorenco/core';

const cleanSlice = ({ __typename, ...slice }: { __typename: string }) =>
  Object.entries(slice).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replace(__typename + '_', '')]: value,
    }),
    { __typename }
  );

// Liste de slices qui n'ont pas de composant slice associées
const IGNORE_SLICES = ['ComponentSlicesTabEnd'];

const GROUP_RULES = {
  ComponentSlicesSection: {
    createParent: (slice: any) => ({ ...slice, children: [] }),
    isEnd: (slice: any) => slice.__typename === 'ComponentSlicesSection',
    normalizeChildren: (slices: any[]) => groupingSlices(slices),
  },
  ComponentSlicesVerticalTab: {
    createParent: (slice: any) => ({
      __typename: 'CustomVerticalList',
      id: slice.id,
      children: [slice],
    }),
    isEnd: (slice: any) => slice.__typename !== 'ComponentSlicesVerticalTab',
    normalizeChildren: (slices: any[]) => slices,
  },
  ComponentSlicesFaq: {
    createParent: (slice: any) => ({
      __typename: 'CustomFaq',
      id: slice.id,
      children: [slice],
    }),
    isEnd: (slice: any) => slice.__typename !== 'ComponentSlicesFaq',
    normalizeChildren: (slices: any[]) => slices,
  },
  ComponentSlicesTestimonial: {
    createParent: (slice: any) => ({
      __typename: 'CustomTestimonial',
      id: slice.id,
      children: [slice],
    }),
    isEnd: (slice: any) => slice.__typename !== 'ComponentSlicesTestimonial',
    normalizeChildren: (slices: any[]) => slices,
  },
  ComponentSlicesTabStart: {
    createParent: (slice: any) => ({
      __typename: 'CustomTabs',
      id: slice.id,
      children: [{ ...slice, __typename: 'CustomTab' }],
    }),
    isEnd: (slice: any) => slice.__typename === 'ComponentSlicesTabEnd',
    normalizeChildren: (slices: any[]) => groupingSlices(slices),
    normalizeSlice: (slice: any) => {
      if (slice.__typename === 'ComponentSlicesTabEnd') {
        return null;
      }

      if (slice.__typename === 'ComponentSlicesTabStart') {
        return { ...slice, __typename: 'CustomTab' };
      }

      return slice;
    },
  },
  CustomTab: {
    createParent: (slice: any) => ({ ...slice, children: [] }),
    isEnd: (slice: any) => slice.__typename === 'CustomTab',
    normalizeChildren: (slices: any[]) => groupingSlices(slices),
  },
};

const groupingSlices = (slices: any[]) => {
  // Régle de regroupement en cours
  let groupRule: any | undefined;

  const groupedSlice = slices.reduce((acc, slice) => {
    // Si un regroupement est en cours, nous continuons jusqu'à la règle de fin
    if (groupRule) {
      // On récupère le groupe
      const parentSlice = acc.pop();

      // On ajoute le slice aux enfants du groupe si ce n'est pas la fin du groupe
      if (!groupRule.isEnd(slice, parentSlice)) {
        const normalizedSlice = groupRule.normalizeSlice
          ? groupRule.normalizeSlice(slice)
          : slice;
        if (!normalizedSlice) {
          return [...acc, parentSlice];
        }

        return [
          ...acc,
          {
            ...parentSlice,
            children: [...parentSlice.children, normalizedSlice],
          },
        ];
      }

      parentSlice.children = groupRule.normalizeChildren(parentSlice.children);
      acc.push(parentSlice);
    }

    // On regarde si le slice en cours enclenche un début de regroupement
    groupRule = GROUP_RULES[slice.__typename as keyof typeof GROUP_RULES];
    if (groupRule) {
      const parentSlice: any = groupRule.createParent(slice);
      return [...acc, parentSlice];
    }

    // Sinon on l'ajoute à la liste des slices
    return IGNORE_SLICES.includes(slice.__typename) ? acc : [...acc, slice];
  }, []);

  if (groupRule) {
    const lastChild = groupedSlice.pop();
    lastChild.children = groupRule.normalizeChildren(lastChild.children);
    groupedSlice.push(lastChild);
  }

  return groupedSlice;
};

export const normalizeSlices = (slices?: any[]) => {
  if (!slices?.length) {
    return [];
  }

  let cleanedSlices: any[] = slices.map(cleanSlice).filter(Boolean);

  // Ajoute un slice section au début si besoin
  if (cleanedSlices[0].__typename !== 'ComponentSlicesSection') {
    cleanedSlices = [
      {
        __typename: 'ComponentSlicesSection',
        id: 'initial-section-slice',
        theme: Types.Enum_Componentslicessection_Theme.FondBleuClair,
        children: [],
      },
      ...cleanedSlices,
    ];
  }

  return groupingSlices(cleanedSlices);
};
