import { useQuery } from '@apollo/client';
import { normalizeSlices, SliceContainer } from '@scorenco/components';
import { ssrApolloService, Types } from '@scorenco/core';
import { NextPageWithLayout } from '@scorenco/next';
import Error from 'next/error';
import { useRouter } from 'next/router';

import { WebLayout } from '@/layouts';

import {
  DebugMode,
  DebugModeStrapiLink,
  StrapiPageSeo,
} from '../../components';
import { RouteKey } from '../../routes';

import { GetLandingPagesDocument } from './_internal/Landing.query.generated';

const Landing: NextPageWithLayout<{ preview?: boolean }> = ({ preview }) => {
  const router = useRouter();

  const slug = router.query.landingSlug as string;

  const pagesQuery = useQuery(GetLandingPagesDocument, {
    context: {
      clientName: 'strapi',
    },
    variables: {
      slug,
      publicationState: preview
        ? Types.PublicationState.Preview
        : Types.PublicationState.Live,
    },
    skip: !slug,
  });

  const landingPage = pagesQuery.data?.pagesLandings?.data?.[0];

  if (!landingPage && !pagesQuery.loading) {
    return <Error statusCode={404} />;
  }

  const seo = landingPage?.attributes?.seo;
  const slices = normalizeSlices(landingPage?.attributes?.body);

  return (
    <>
      {seo && (
        <StrapiPageSeo
          seo={seo}
          url={{ routeKey: RouteKey.Landing, params: { landingSlug: slug } }}
        />
      )}

      <SliceContainer slices={slices} />

      <DebugMode preview={preview}>
        <DebugModeStrapiLink type="pages-landing" strapiId={landingPage?.id} />
      </DebugMode>
    </>
  );
};

export const getServerSideProps = WebLayout.buildGetServerSideProps({}, [
  async (options, acc) => {
    options.res.setHeader(
      'Cache-Control',
      `public, s-maxage=${30 * 24 * 60 * 60}, max-age=${15 * 60}`
    );

    const { apolloClient, addApolloState } = ssrApolloService();

    if (options.query['no-ssr'] === undefined) {
      const getLandingPageQuery = await apolloClient.query({
        query: GetLandingPagesDocument,
        context: {
          clientName: 'strapi',
        },
        variables: {
          slug: options.params?.landingSlug as string,
          publicationState: Types.PublicationState.Live,
        },
      });

      const landingPage = getLandingPageQuery.data?.pagesLandings?.data?.[0];
      if (!landingPage) {
        return { notFound: true };
      }
    }

    return addApolloState({
      ...acc,
      props: { ...acc.props, preview: options?.preview ? true : null },
    });
  },
]);

/**
 * --------------------------------------------------------------------------------
 * nextjs-lambda doesn't support ISR yet. We have a read-only issue.
 * The solution is to shared the ISR cache : https://github.com/vercel/next.js/discussions/19589
 * It's still experimental :'(
 * --------------------------------------------------------------------------------
 *
 * export const getStaticProps = WebLayout.buildGetStaticProps({}, [
 *   async (options: GetStaticPropsContext, acc: { props: any }) => {
 *     const { apolloClient, addApolloState } = ssrApolloService();
 *
 *     try {
 *       await WebLayoutAppBarActions.ssrQuery({ apolloClient });
 *       await Footer.ssrQuery({ apolloClient });
 *
 *       await apolloClient.query({
 *         query: GetLandingPagesDocument,
 *         context: {
 *           clientName: 'strapi',
 *         },
 *         variables: {
 *           slug: options.params?.landingSlug as string,
 *           publicationState: PublicationState.Live,
 *         },
 *       });
 *     } catch (err) {
 *       //Do nothing
 *       console.log('err : ', err);
 *     }
 *
 *     return addApolloState({
 *       ...acc,
 *       props: { ...acc.props, preview: options?.preview ? true : null },
 *     });
 *   },
 * ]);
 *
 *
 * export const getStaticPaths = WebLayout.buildGetStaticPaths([
 *   async () => {
 *     const { apolloClient } = ssrApolloService();
 *
 *     const slugQuery = await apolloClient.query({
 *       query: GetAllLangingPageSlugDocument,
 *       context: {
 *         clientName: 'strapi',
 *       },
 *     });
 *
 *     return {
 *       paths:
 *         slugQuery.data?.pagesLandings?.data?.map(
 *           (page) => `/landing/${page.attributes?.slug}`
 *         ) || [],
 *       fallback: false,
 *     };
 *   },
 * ]);
 *
 **/

Landing.getLayout = WebLayout.buildGetLayout({
  strapiMenu: true,
  amplify: { protectedPage: false },
});

export default Landing;
