import { Box } from '@mui/material';
import { Types } from '@scorenco/core';

import { defaultSlicesMapper, SliceMapper } from './slice-mapper';

export type SliceContainerProps = {
  slices?: ({ __typename: string } | Types.Error | undefined)[];
  mapper?: SliceMapper;
};

export const SliceContainer = ({
  slices,
  mapper = defaultSlicesMapper,
}: SliceContainerProps) => {
  return slices?.map((slice, index) => {
    if (!slice) {
      return null;
    }

    if (slice.__typename === 'Error') {
      return <Box key={index}>{(slice as Types.Error).message}</Box>;
    }

    const Slice = mapper[slice.__typename];
    const sliceId = (slice as any).id as string;

    if (!Slice || !sliceId) {
      console.log('error : ', { mapper, name: slice.__typename, sliceId });
      return null;
    }

    return (
      <Slice
        key={`${slice.__typename}-${sliceId}`}
        slice={slice}
        mapper={
          slice.__typename.includes('ComponentSlicesSection')
            ? mapper
            : undefined
        }
      />
    );
  });
};
