import { ComponentType } from 'react';

import { Box } from '@mui/system';
import dynamic from 'next/dynamic';

const AdvertisingSlice = dynamic(
  async () => (await import('../AdvertisingSlice')).AdvertisingSlice,
  { loading: () => <Box height={200} /> }
);
const FaqSlice = dynamic(async () => (await import('../FaqSlice')).FaqSlice, {
  loading: () => <Box height={200} />,
});
const FeaturesListSlice = dynamic(
  async () => (await import('../FeaturesListSlice')).FeaturesListSlice,
  { loading: () => <Box height={200} /> }
);
const HeroSlice = dynamic(
  async () => (await import('../HeroSlice')).HeroSlice,
  { loading: () => <Box height={200} /> }
);
const ImageSlice = dynamic(
  async () => (await import('../ImageSlice')).ImageSlice,
  { loading: () => <Box height={200} /> }
);
const ImageWithTextSlice = dynamic(
  async () => (await import('../ImageWithTextSlice')).ImageWithTextSlice,
  { loading: () => <Box height={200} /> }
);
const LinkListSlice = dynamic(
  async () => (await import('../LinkListSlice')).LinkListSlice,
  { loading: () => <Box height={200} /> }
);
const LivesSliderSlice = dynamic(
  async () => (await import('../LivesSliderSlice')).LivesSliderSlice,
  { loading: () => <Box height={200} /> }
);
const NewsletterSlice = dynamic(
  async () => (await import('../NewsletterSlice')).NewsletterSlice,
  { loading: () => <Box height={200} /> }
);
const SectionSlice = dynamic(
  async () => (await import('../SectionSlice')).SectionSlice,
  { loading: () => <Box height={200} /> }
);
const TabsSlice = dynamic(
  async () => (await import('../TabsSlice')).TabsSlice,
  { loading: () => <Box height={200} /> }
);
const TeamSlice = dynamic(
  async () => (await import('../TeamSlice')).TeamSlice,
  { loading: () => <Box height={200} /> }
);
const TestimonialSlice = dynamic(
  async () => (await import('../TestimonialSlice')).TestimonialSlice,
  { loading: () => <Box height={200} /> }
);
const TextSlice = dynamic(
  async () => (await import('../TextSlice')).TextSlice,
  { loading: () => <Box height={200} /> }
);
const VerticalListSlice = dynamic(
  async () => (await import('../VerticalListSlice')).VerticalListSlice,
  { loading: () => <Box height={200} /> }
);
const SportListSlice = dynamic(
  async () => (await import('../SportListSlice')).SportListSlice,
  { loading: () => <Box height={200} /> }
);
const StoreGradeSlice = dynamic(
  async () => (await import('../StoreGradeSlice')).StoreGradeSlice,
  { loading: () => <Box height={200} /> }
);
const HtmlSlice = dynamic(
  async () => (await import('../HtmlSlice')).HtmlSlice,
  { loading: () => <Box height={200} /> }
);
const PricingSlice = dynamic(
  async () => (await import('../PricingSlice')).PricingSlice,
  { loading: () => <Box height={200} /> }
);
const ReviewsSlice = dynamic(
  async () => (await import('../ReviewsSlice')).ReviewsSlice,
  { loading: () => <Box height={200} /> }
);
const KeysFiguresSlice = dynamic(
  async () => (await import('../KeysFiguresSlice')).KeysFiguresSlice,
  { loading: () => <Box height={200} /> }
);

export type SliceMapper = Record<string, ComponentType<any>>;

export const defaultSlicesMapper: SliceMapper = {
  ComponentSlicesAdvertising: AdvertisingSlice,
  ComponentSlicesHero: HeroSlice,
  ComponentSlicesImage: ImageSlice,
  ComponentSlicesImageWithText: ImageWithTextSlice,
  ComponentSlicesLinkList: LinkListSlice,
  ComponentSlicesNewsletter: NewsletterSlice,
  ComponentSlicesSection: SectionSlice,
  ComponentSlicesText: TextSlice,
  CustomFaq: FaqSlice,
  CustomTabs: TabsSlice,
  CustomTestimonial: TestimonialSlice,
  CustomVerticalList: VerticalListSlice,
  ComponentSlicesFeaturesList: FeaturesListSlice,
  ComponentSlicesTeam: TeamSlice,
  ComponentSlicesLivesSlider: LivesSliderSlice,
  ComponentSlicesSportList: SportListSlice,
  ComponentSlicesStoreGrade: StoreGradeSlice,
  ComponentSlicesHtml: HtmlSlice,
  ComponentSlicesPricing: PricingSlice,
  ComponentSlicesReviews: ReviewsSlice,
  ComponentSlicesKeysFigures: KeysFiguresSlice,
};
